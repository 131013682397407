import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppBreadcrumbService {
    private breadcrumbs = new BehaviorSubject<MenuItem[]>([]);
    breadcrumbs$ = this.breadcrumbs.asObservable();

    constructor(private router: Router) {
        const breadcrumbs = this.createBreadcrumbs(this.router.url);
        this.breadcrumbs.next(breadcrumbs);

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                const breadcrumbs = this.createBreadcrumbs(this.router.url);
                this.breadcrumbs.next(breadcrumbs);
        });
    }

    private createBreadcrumbs(url: string): MenuItem[] {
        const breadcrumbs: MenuItem[] = [];
        const urlSegments = url.split('/').filter(segment => segment)
        let currentUrl = '';
        urlSegments.forEach((segment) => {
            currentUrl += `/${segment}`;
            const breadcrumb: MenuItem = {
                label: segment,
                routerLink: currentUrl
            };
            breadcrumbs.push(breadcrumb);
        });

        return breadcrumbs;
    }

    
}
