<p-breadcrumb [model]="breadcrumbs">
    <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="item.route; else elseBlock">
            <a [routerLink]="item.route" class="p-menuitem-link">
                <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
                <span class="text-primary font-semibold">{{ item.label }}</span>
            </a>
        </ng-container>
        <ng-template #elseBlock>
            <span class="text-color capitalize">{{ item.label }}</span>
        </ng-template>
    </ng-template>
</p-breadcrumb>