import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { take, tap } from 'rxjs';

@Component({
    selector: 'app-profilemenu',
    templateUrl: './app.profilesidebar.component.html',
})
export class AppProfileSidebarComponent {
    constructor(
        public layoutService: LayoutService,
        private readonly authService: AuthService,
        private router: Router,
    ) {}

    get visible(): boolean {
        return this.layoutService.state.profileSidebarVisible;
    }

    set visible(_val: boolean) {
        this.layoutService.state.profileSidebarVisible = _val;
    }

    signOut() {
        this.authService
            .logoutUser()
            .pipe(
                take(1),
                tap(() => {
                    this.router.navigate(['/auth']).then();
                }),
            )
            .subscribe();
    }
}
