import { inject, Injectable } from '@angular/core';
import { ProvidersService } from '../providers/providers.service';
import { filter, Observable, shareReplay, take } from 'rxjs';
import { DrahimProvider } from './schemas';

@Injectable({ providedIn: 'root' })
export class ConstantsService {
    private readonly providersService: ProvidersService = inject(ProvidersService);

    public readonly providers: Observable<DrahimProvider[]> = this.providersService.getProvidersList().pipe(
        take(1),
        filter((res) => !!res),
        shareReplay(),
    );
}
