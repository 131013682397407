<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
        <app-breadcrumb class="topbar-breadcrumb"></app-breadcrumb>
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-profile">
                <button type="button" class="p-link" (click)="onProfileButtonClick()">
                    <span class="pi pi-user"></span>
                </button>
            </li>
        </ul>
    </div>
</div>
