import { Component } from '@angular/core';
import { AppBreadcrumbService } from './service/breadcrumb.service';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
})

export class AppBreadcrumbComponent {
    breadcrumbs: MenuItem[] = [];
    constructor(private breadcrumbService: AppBreadcrumbService) { }

    ngOnInit(): void {
        this.breadcrumbService.breadcrumbs$.subscribe((items) => {
            this.breadcrumbs = items;
        });
    }
}
