<p-sidebar
    [(visible)]="visible"
    position="right"
    [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
    styleClass="layout-profile-sidebar w-full sm:w-25rem"
>
    <div class="flex flex-column mx-auto md:mx-0">
        <ul class="list-none m-0 p-0">
            <a
                (click)="signOut()"
                class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150"
            >
                <span>
                    <i class="pi pi-power-off text-xl text-primary"></i>
                </span>
                <div class="ml-3">
                    <span class="mb-2 font-semibold">Sign Out</span>
                </div>
            </a>
        </ul>
    </div>
</p-sidebar>
