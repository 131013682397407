import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DrahimProvider } from '../shared/schemas';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ProvidersService {
    constructor(private readonly http: HttpClient) {}

    getProvidersList() {
        return this.http.get<DrahimProvider[]>(`${environment.baseUrl}/admin/providers`);
    }

    getProviderById(id: string) {
        return this.http.get<DrahimProvider>(`${environment.baseUrl}/admin/providers/${id}`);
    }
}
