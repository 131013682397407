export enum MarketOrderType {
    BUY_AMOUNT = 'BUY_AMOUNT',
    SELL_AMOUNT = 'SELL_AMOUNT',
    SELL_QTY = 'SELL_QTY',
}

export enum MarketOrderStatus {
    CREATED = 'CREATED',
    STARTED = 'STARTED',
    STARTED_PENDING_CONFIRMATION = 'STARTED_PENDING_CONFIRMATION',
    COMPLETED = 'COMPLETED',
    COMPLETED_PENDING_CONFIRMATION = 'COMPLETED_PENDING_CONFIRMATION',
    PROCESSED = 'PROCESSED',
    PROCESSED_PENDING_CONFIRMATION = 'PROCESSED_PENDING_CONFIRMATION',
    FAILED = 'FAILED',
    FAILED_PENDING_CONFIRMATION = 'FAILED_PENDING_CONFIRMATION',
}
