import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { MenuItem } from 'primeng/api';
import { takeWhile, tap } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit, OnDestroy {
    @Input() shouldShowProviders: boolean = true;
    menuItems: MenuItem[] = [];

    destroyed = false;

    constructor(private readonly appLayoutService: LayoutService) {}

    ngOnInit() {
        this.appLayoutService.menuItems$
            .pipe(
                takeWhile(() => !this.destroyed),
                tap((items) => {
                    this.menuItems = items;
                }),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.destroyed = true;
    }
}
