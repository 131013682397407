import { Component, Input } from '@angular/core';
import { ManualEngineAllocationRequestStatus } from '../../cash-requests/enums';
import { MarketOrderStatus } from '../../market-orders/enums';

@Component({
    selector: 'app-status-tag',
    template: ` <p-tag class="uppercase text-sm" [severity]="mapOrderStatusToSeverity(status)" [value]="status!" /> `,
})
export class StatusTagComponent {
    @Input() status: ManualEngineAllocationRequestStatus | MarketOrderStatus | null = null;

    mapOrderStatusToSeverity(
        orderStatus: ManualEngineAllocationRequestStatus | MarketOrderStatus | null,
    ): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (orderStatus) {
            case ManualEngineAllocationRequestStatus.COMPLETED || MarketOrderStatus.COMPLETED:
                return 'success';
            case ManualEngineAllocationRequestStatus.STARTED || MarketOrderStatus.STARTED:
                return 'warning';
            case ManualEngineAllocationRequestStatus.COMPLETED_PENDING_CONFIRMATION ||
                MarketOrderStatus.STARTED_PENDING_CONFIRMATION:
                return 'warning';
            case MarketOrderStatus.CREATED:
                return 'success';
            default:
                return undefined;
        }
    }
}
